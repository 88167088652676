import {effect, signal, Signal} from '@angular/core';

export function debouncedSignal<T>(input: Signal<T>, timeOutMs = 0): Signal<T> {
  const debounceSignal = signal(input());
  let timeoutId: any = null;

  effect(() => {
    const value = input();

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      debounceSignal.set(value);
    }, timeOutMs);

    return () => {
      clearTimeout(timeoutId);
    };
  });

  return debounceSignal;
}
