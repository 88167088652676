import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {RDC} from "@shared/models/rdc";
import moment from 'moment';
import {Payor} from "@shared/models/payor";


export function getPayorName(payor: Payor) {
  if (!payor) return null;
  return payor.name ? payor.name.split('|')[0] : null;
}

export function getPayorExtraNamesFormArray(payor: Payor) {
  const result = new FormArray([]);
  if (payor && payor.name) {
    payor.name.split('|')
      .map(name => name.trim())
      .forEach((value, index) => {
        if (index > 0) {
          result.push(new FormControl(value));
        }
      });
  }
  return result;
}

export function getFormFromRDC(rdc: RDC) {
  return new FormGroup({
    check_number: new FormControl(!rdc.check_number ? '' : rdc.check_number, [Validators.required]),
    check_acc_no: new FormControl(!rdc.check_acc_no ? '' : rdc.check_acc_no, [Validators.required]),
    check_routing_aba: new FormControl(!rdc.check_routing_aba ? '' : rdc.check_routing_aba, [Validators.required]),
    checkAmount: new FormControl(rdc.check_amount, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
    checkIssueDate: new FormControl(rdc.check_issue_date ? moment(rdc.check_issue_date, 'YYYY-MM-DD').toDate() : null, Validators.required),
    memo: new FormControl(rdc.memo),
    intermediary_party: new FormControl(rdc.intermediary_party),
    payorName: new FormControl(rdc.payor, Validators.required), //pass object of Payor as the input is controlled by ngbTypeahead
    payorAddress: new FormControl(rdc.payor ? rdc.payor.address : null),
    payorCity: new FormControl(rdc.payor ? rdc.payor.city : null),
    payorZip: new FormControl(rdc.payor ? rdc.payor.zip : null),
    payorCountry: new FormControl(rdc.payor ? rdc.payor.country : null),
    payorState: new FormControl(rdc.payor ? rdc.payor.state : null),
    payorExtraNames: getPayorExtraNamesFormArray(rdc.payor),
    front_jpg_filename: new FormControl(rdc.front_jpg_filename ? rdc.front_jpg_filename : null),
    back_jpg_filename: new FormControl(rdc.back_jpg_filename ? rdc.back_jpg_filename : null),
    front_tiff_filename: new FormControl(rdc.front_tiff_filename ? rdc.front_tiff_filename : null),
    back_tiff_filename: new FormControl(rdc.back_tiff_filename ? rdc.back_tiff_filename : null)
  });
}

export function isFormControlInvalid(formGroup: FormGroup, formControlName: string) {
  return (
    !formGroup.get(formControlName).valid ||
    formGroup.get(formControlName).hasValidator(Validators.required) && formGroup.get(formControlName).value === ''
  ) && formGroup.get(formControlName).dirty;
}
