import {Component, Input, OnInit} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {FormGroup} from '@angular/forms';
import {isFormControlInvalid} from '@app/shared-module/utils/forms.utils';

@Component({
  selector: "design-tailwind-input",
  templateUrl: "./tailwind-input.component.html",
  styleUrls: ["./tailwind-input.component.scss"],
  animations: [routerTransition()]
})
export class TailwindInputComponent implements OnInit {

  constructor() {
  }

  @Input() formGroup: FormGroup;
  @Input() fControlName: string;
  @Input() label: string;
  @Input() required = false;

  protected readonly isFormControlInvalid = isFormControlInvalid;

  ngOnInit() {
  }
}


