<div class="design-table overflow-y-auto px-4 sm:overflow-visible sm:px-0">
  <table class="{{tableClass}}">
    <thead class="{{tableThreadClass}}">
    <tr #tableHeaders>
      <ng-content select="[tableHeader]"></ng-content>
    </tr>
    </thead>
    <tbody id="{{tableBodyId}}" class="{{tableBodyClass}}" #tableBody>
      <ng-content select="[tableBody]"></ng-content>
    </tbody>
  </table>
</div>
<!-- Pager -->
<ng-container>
  <div class="pager-message">{{ 'design.tailwind-table: {itemsCount} Total' | translate: {itemsCount: itemsCount} }}</div>
  <div class="pager flex justify-center mt-4" *ngIf="showPager && pages > 1">
    <nav aria-label="Pagination">
      <ul class="flex list-none">
        <li *ngIf="showPagerNavigation" [ngClass]="{'invisible': this.pagerRenderRange[0] == 0}"><a (click)="pagerGoBackward()" class="pager-button">
          <span class="hero-arrow-left-mini"></span>
        </a></li>
        @for(page of [].constructor(pages); track pages; let pageIndex = $index) {
          <li *ngIf="pageIndex >= this.pagerRenderRange[0] && pageIndex < this.pagerRenderRange[1]">
            <a (click)="goToPage(pageIndex)"
              class="pager-button"
              [ngClass]="{'pager-button-selected': pageIndex == currentPage}">
              {{pageIndex + 1}}
            </a>
          </li>
        }
        <li *ngIf="showPagerNavigation" [ngClass]="{'invisible': this.pagerRenderRange[1] >= this.pages}"><a (click)="pagerGoForward()" class="pager-button">
          <span class="hero-arrow-right-mini"></span>
        </a></li>
      </ul>
    </nav>
  </div>
</ng-container>

<!-- Sorting -->
<ng-template #sortElementTemplate>
  <span class="hover:cursor-pointer sort-icon" (click)="sort($event)"></span>
</ng-template>
