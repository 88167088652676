<form [formGroup]="formGroup">
  <label class="block text-sm font-semibold leading-6 text-zinc-800" [for]="formControlName">{{label}}
    <span *ngIf="required" class="required-field"></span>
  </label>
  <select
    #selectElement
    [id]="formControlName"
    [formControlName]="formControlName"
    (blur)="onTouched()"
    (focusout)="formGroup.get(formControlName).markAsDirty()"
    (change)="onChange($event.target['value'])"
    class="modal-input-select" [ngClass]="{'input-required-error': isFormControlInvalid(formGroup, formControlName)}">
    <ng-content></ng-content>
  </select>
</form>
