import {AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {FormGroup, NG_VALUE_ACCESSOR, SelectControlValueAccessor, Validators} from '@angular/forms';
import {isFormControlInvalid} from '@app/shared-module/utils/forms.utils';

@Component({
  selector: "design-tailwind-input-select",
  templateUrl: "./tailwind-input-select.component.html",
  styleUrls: ["./tailwind-input-select.component.scss"],
  animations: [routerTransition()],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TailwindInputSelectComponent)
    },
    {
      provide: SelectControlValueAccessor,
      useExisting: forwardRef(() => TailwindInputSelectComponent)
    }
  ]
})
export class TailwindInputSelectComponent extends SelectControlValueAccessor implements AfterViewInit {
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() label: string;
  @Input() required = false;
  @ViewChild('selectElement') selectElement: ElementRef;

  protected readonly isFormControlInvalid = isFormControlInvalid;
  readonly properties;

  constructor(elementRef: ElementRef) {
    super(
      <any>{
        setProperty(_, property, value) {
          this.properties = this.properties || {};
          this.properties[property] = value;
        }
      },
      {nativeElement: elementRef.nativeElement}
    );
    this.properties = this.properties || {};
  }

  ngAfterViewInit(): void {
    this.setDefaultValue();
  }

  // ! This method only works with ngValue
  private setDefaultValue() {
    const controlValue = this.formGroup.get(this.formControlName).value;
    const options = this.selectElement.nativeElement.querySelectorAll('option');
    for (const option of options) {
      if (option.getAttribute('ng-reflect-ng-value') === null && (
        controlValue === null ||
        this.formGroup.get(this.formControlName).hasValidator(Validators.required) && this.formGroup.get(this.formControlName).value === '')
      ) {
        option.selected = true;
        break;
      }
      if (option.getAttribute('ng-reflect-ng-value') === String(controlValue)) {
        option.selected = true;
        break;
      }
    }
  }
}
