import {animate, state, style, transition, trigger} from '@angular/animations';

export function routerTransition() {
  // return slideToTop();
  return trigger('routerTransition', []);
}

export function dropdownAnimation() {
  // return dropDown()
  return trigger('dropdownAnimation', []);
}

export function rotateArrowAnimation() {
  // return rotateArrow
  return trigger('rotateArrowAnimation', []);
}

export function slideToRight() {
  return trigger('routerTransition', [
    state('void', style({})),
    state('*', style({})),
    transition(':enter', [
      style({transform: 'translateX(-100%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(0%)'}))
    ]),
    transition(':leave', [
      style({transform: 'translateX(0%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(100%)'}))
    ])
  ]);
}

export function slideToLeft() {
  return trigger('routerTransition', [
    state('void', style({})),
    state('*', style({})),
    transition(':enter', [
      style({transform: 'translateX(100%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(0%)'}))
    ]),
    transition(':leave', [
      style({transform: 'translateX(0%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(-100%)'}))
    ])
  ]);
}

export function slideToBottom() {
  return trigger('routerTransition', [
    state('void', style({})),
    state('*', style({})),
    transition(':enter', [
      style({transform: 'translateY(-100%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateY(0%)'}))
    ]),
    transition(':leave', [
      style({transform: 'translateY(0%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateY(100%)'}))
    ])
  ]);
}

export function slideToTop() {
  return trigger('routerTransition', [
    state('void', style({})),
    state('*', style({})),
    transition(':enter', [
      style({transform: 'translateY(100%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateY(0%)'}))
    ]),
    transition(':leave', [
      style({transform: 'translateY(0%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateY(-100%)'}))
    ])
  ]);
}

export function dropDown() {
  return trigger('dropdownAnimation', [
    state('open', style({
      opacity: 1,
      height: '*',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      paddingLeft: '1.25rem'
    })),
    state('closed', style({
      opacity: 0,
      height: '0',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
    })),
    transition('open <=> closed', [
      animate('300ms ease-in-out'),
    ])
  ]);
}

export function rotateArrow() {
  return trigger('rotateArrowAnimation', [
    state('initial', style({
      transform: 'rotate(0deg)'
    })),
    state('dropdown', style({
      transform: 'rotate(180deg)'
    })),
    transition('initial => dropdown', [
      animate('300ms ease-in-out')
    ]),
    transition('dropdown => initial', [
      animate('300ms ease-in-out')
    ])
  ]);
}
