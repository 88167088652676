<div #datePickerMain class="antialiased">
  <div class="{{dateInputClass}}">
    <div class="relative">
      <input type="text"
             [ngClass]="{
                'date-input-error': (errorMessage || hasInputErrors()) && !forceWhiteTheme,
                'fw-date-input-error': (errorMessage || hasInputErrors()) && forceWhiteTheme,
                'date-input': (!errorMessage || !hasInputErrors()) && !forceWhiteTheme,
                'fw-date-input': (!errorMessage || !hasInputErrors()) && forceWhiteTheme
             }"
             placeholder="{{'design.tailwind-date-picker: Select Date' | translate}}"
             [(ngModel)]="datePickerInputValue"
             (keydown.enter)="setDateFromInput($event)"
             (focusout)="setDateFromInput($event)">

      <div class="date-picker-toggle-button" (click)="toggle()">
        <svg class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
        </svg>
      </div>
      <div *ngIf="isDatePickerRendered" class="min-w-full">
        <div class="w-64 shadow-lg"
             [ngClass]="{
                'date-picker-anim-open': shouldDatePickerBeVisible,
                'date-picker-anim-close': !shouldDatePickerBeVisible,
                'date-picker': !forceWhiteTheme,
                'fw-date-picker': forceWhiteTheme,
              }">
          <div class="flex justify-between items-center mb-2">
            <div>
              <button *ngIf="datePickerState !== DatePickerState.SELECT_MONTH && datePickerState !== DatePickerState.SELECT_YEAR"
                      (click)="setDatePickerState(DatePickerState.SELECT_MONTH, $event)"
                      [ngClass]="{'date-picker-button-large': !forceWhiteTheme, 'fw-date-picker-button-large': forceWhiteTheme}">{{ ('design.tailwind-date-picker: ' + monthNames[month]) | translate }}</button>
              <button *ngIf="datePickerState !== DatePickerState.SELECT_YEAR"
                      class="ml-1"
                      [ngClass]="{
                    'date-picker-button-large': !forceWhiteTheme && datePickerState !== DatePickerState.SELECT_MONTH,
                    'fw-date-picker-button-large': forceWhiteTheme && datePickerState !== DatePickerState.SELECT_MONTH,
                    'date-picker-button-large-disabled': !forceWhiteTheme && datePickerState === DatePickerState.SELECT_MONTH,
                    'fw-date-picker-button-large-disabled': forceWhiteTheme && datePickerState === DatePickerState.SELECT_MONTH
                  }"
                      [disabled]="datePickerState === DatePickerState.SELECT_MONTH"
                      (click)="setDatePickerState(DatePickerState.SELECT_YEAR, $event)">
                {{ year }}
              </button>
            </div>
            <div>
              <button *ngIf="datePickerState !== DatePickerState.SELECT_MONTH" type="button" (click)="navigationLeft()"
                      [ngClass]="{
                        'date-picker-button': !forceWhiteTheme,
                        'fw-date-picker-button': forceWhiteTheme
                      }">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <button type="button" (click)="navigationRight()" class="date-picker-button">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
          <!-- Select Day -->
          <span *ngIf="datePickerState === DatePickerState.SELECT_DAY"
                [ngClass]="{'date-picker-anim-show': shouldDatePickerStateBeVisible[DatePickerState.SELECT_DAY], 'date-picker-anim-hide': !shouldDatePickerStateBeVisible[DatePickerState.SELECT_DAY]}">
            <div>
              <div class="date-picker-days-header">
                <div [ngClass]="{'date-picker-day-name': !forceWhiteTheme, 'fw-date-picker-day-name': forceWhiteTheme}" *ngFor="let day of days">
                  {{ ('design.tailwind-date-picker: ' + day) | translate }}
                </div>
              </div>

              <div class="date-picker-days-content">
                <div *ngFor="let date of getPrevMonthDays()" class="w-7 cursor-pointer date-picker-day-grayed" (click)="selectDate(date, month - 1); close();">{{ date }}</div>
                <div *ngFor="let date of getNoOfDays()" class="w-7 cursor-pointer date-picker-day" (click)="selectDate(date, month); close();">
                  <div [ngClass]="{
                    'bg-blue-500': isToday(date) && !isSelected(date),
                    'date-picker-day-selected': isSelected(date) && !forceWhiteTheme,
                    'fw-date-picker-day-selected': isSelected(date) && forceWhiteTheme,
                    'date-picker-day': !forceWhiteTheme,
                    'fw-date-picker-day': forceWhiteTheme
                  }">{{ date }}</div>
                </div>
                <div *ngFor="let date of getNextMonthDays()" class="w-7 cursor-pointer date-picker-day-grayed" (click)="selectDate(date, month + 1); close();">{{ date }}</div>
              </div>
            </div>
          </span>
          <!-- Select Month -->
          <span *ngIf="datePickerState === DatePickerState.SELECT_MONTH"
                [ngClass]="{'date-picker-anim-show': shouldDatePickerStateBeVisible[DatePickerState.SELECT_MONTH], 'date-picker-anim-hide': !shouldDatePickerStateBeVisible[DatePickerState.SELECT_MONTH]}">
            <div class="date-picker-month-content">
              @for(month of monthNames; track month; let monthIndex = $index) {
                <div [ngClass]="{'date-picker-month': !forceWhiteTheme, 'fw-date-picker-month': forceWhiteTheme,}" (click)="selectMonth(monthIndex)">
                  {{ ('design.tailwind-date-picker: ' + month) | translate }}
                </div>
              }
            </div>
          </span>
          <!-- Select Year -->
          <span *ngIf="datePickerState === DatePickerState.SELECT_YEAR"
                      [ngClass]="{'date-picker-anim-show': shouldDatePickerStateBeVisible[DatePickerState.SELECT_YEAR], 'date-picker-anim-hide': !shouldDatePickerStateBeVisible[DatePickerState.SELECT_YEAR]}">
            <div class="date-picker-year-content">
              @for(yearToRender of yearsToRender; track yearToRender) {
                <div [ngClass]="{
                      'date-picker-year': !forceWhiteTheme,
                      'fw-date-picker-year': forceWhiteTheme,
                      'bg-blue-500': isCurrentYear(yearToRender) && !isYearSelected(yearToRender),
                      'date-picker-year-selected': isYearSelected(yearToRender) && !forceWhiteTheme,
                      'fw-date-picker-year-selected': isYearSelected(yearToRender) && forceWhiteTheme,
                    }" (click)="selectYear(yearToRender)">
                  {{ yearToRender }}
                </div>
              }
            </div>
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="this.errorMessage" [ngClass]="{'fw-date-picker-error-message': forceWhiteTheme, 'date-picker-error-message': !forceWhiteTheme}">
      {{ this.errorMessage }}
    </div>
  </div>
</div>
