import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Country_ISO} from '@shared/models/country_iso';
import {CountriesService} from '@app/core-module/services/countries.service';
import {getStates} from '@app/shared-module/utils/utils';
import {ChangeDetectorRef} from '@angular/core';

@Component({
  selector: "design-tailwind-input-select-country",
  templateUrl: "./tailwind-input-select-country.component.html",
  styleUrls: ["./tailwind-input-select-country.component.scss"],
  animations: [routerTransition()]
})
export class TailwindInputSelectCountryComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup;
  @Input() countryControlName: string;
  @Input() stateControlName: string;
  @Input() countryLabel: string;
  @Input() stateLabel: string;
  @Input() required = false;
  @Input() showStateInput = true;

  countries: Observable<Country_ISO[]>;

  states = [];

  countrySubscription: Subscription;

  constructor(private countriesService: CountriesService, private cd: ChangeDetectorRef) {
    this.countries = this.countriesService.getCountries();
  }

  ngOnInit() {
    if (this.countryControlName && this.stateControlName) {
      const countryControl = this.formGroup.controls[this.countryControlName] as FormControl;
      const stateControl = this.formGroup.controls[this.stateControlName] as FormControl;

      this.states = getStates(countryControl.value);

      this.countrySubscription = countryControl.valueChanges.subscribe(value => {
        this.states = getStates(countryControl.value);

        this.cd.detectChanges();

        if ((value === 'USA' || value === 'MEX') && this.showStateInput) {
          stateControl.setValidators(Validators.required);
          stateControl.updateValueAndValidity();
        } else {
          stateControl.setValue(null);
          stateControl.clearValidators();
          stateControl.setErrors(null);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.countrySubscription) {
      this.countrySubscription.unsubscribe();
    }
  }
}


