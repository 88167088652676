import {Injectable} from '@angular/core';
import {Observable, Observer, Subscription} from 'rxjs';
import {ScanningMessage} from '@shared/models/scanning.message';
import {User} from '@shared/models/user';
import {first} from 'rxjs/operators';
import {Socket} from 'ngx-socket-io';

@Injectable()
export class ScanningService {
  errorSubscription: Subscription;
  scanSubscription: Subscription;
  scanningObserver: Observer<ScanningMessage>;
  timeout = null;
  scanningOne = false;

  constructor(private socket: Socket) {
    this.init();
  }

  public init() {
    // Error Handler
    this.errorSubscription = this.socket.fromEvent<any, 'error'>('error').subscribe(err => {
      if (this.scanningObserver) {
        this.scanningObserver.error(err);
      }
    });
    // Scan
    this.scanSubscription = this.socket.fromEvent<ScanningMessage, 'message'>('message').subscribe(data => {
      if (!this.scanningObserver) {
        return;
      }
      this.scanningObserver.next(data);
      if (data.error) {
        this.scanningObserver.error(new Error(data.error));
      } else {
        if (data.failedToScan && this.timeout == null) {
          this.timeout = setTimeout(() => {
            this.scanningObserver.complete();
          }, 10000);
        }
        if (!data.failedToScan && this.timeout != null) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
        if ((data.result || data.scannedCheck) && this.scanningOne) { // result if valid scanning, scannedCheck if check is duplicate
          this.scanningObserver.complete();
        }
      }
    });
  }

  public scanOne(user: User, stopMessage: Observable<void>) {
    return Observable.create((observer: Observer<ScanningMessage>) => {
      this.scan('scan-one', user, observer, stopMessage);
      this.scanningOne = true;
    });
  }

  public scanBatch(user: User, stopMessage: Observable<void>): Observable<ScanningMessage> {
    return Observable.create((observer: Observer<ScanningMessage>) => {
      this.scan('scan-batch', user, observer, stopMessage);
      this.scanningOne = false;
    }) as Observable<ScanningMessage>;
  }
  public scan(eventName: string, user: User, observer: Observer<ScanningMessage>, stopMessage: Observable<void>) {
    this.socket.emit(eventName, {id: user.id});
    this.scanningObserver = observer;
    // Scanning Stop Message
    stopMessage.pipe(first()).subscribe(() => {
      this.scanningObserver.complete();
    });
  }

}
