import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[tailwindTooltip]'
})
export class TooltipDirective {
  @Input('tailwindTooltip') tooltipText: string;
  private tooltipElement: HTMLElement;
  private destroyTimeout: any = null;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private createTooltip() {
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.appendChild(
      this.tooltipElement,
      this.renderer.createText(this.tooltipText)
    );
    // Add classes one by one
    this.renderer.addClass(this.tooltipElement, 'tailwind-tooltip');

    this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
    this.renderer.setStyle(this.tooltipElement, 'transition', 'opacity 0.2s ease-in-out');
    this.renderer.setStyle(this.tooltipElement, 'pointer-events', 'none');
    this.renderer.appendChild(document.body, this.tooltipElement);
  }

  private destroyTooltip() {
    clearTimeout(this.destroyTimeout);
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

  private positionTooltip(event: MouseEvent) {
    const { clientX, clientY } = event;
    const tooltipWidth = this.tooltipElement.offsetWidth;
    const tooltipHeight = this.tooltipElement.offsetHeight;
    const posLeft = clientX - tooltipWidth / 2;
    const posTop = clientY - tooltipHeight - 8;

    this.renderer.setStyle(this.tooltipElement, 'left', `${posLeft}px`);
    this.renderer.setStyle(this.tooltipElement, 'top', `${posTop}px`);
    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent) {
    this.destroyTooltip();
    this.createTooltip();
    this.positionTooltip(event);
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.tooltipElement) {
      this.positionTooltip(event);
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.tooltipElement) {
      this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
      this.destroyTimeout = setTimeout(() => {
        this.destroyTooltip();
      }, 200);
    }
  }
}
