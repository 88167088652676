import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {Router} from '@angular/router';

@Component({
  selector: "design-tailwind-modal",
  templateUrl: "./tailwind-modal.component.html",
  styleUrls: ["./tailwind-modal.component.scss"],
  animations: [routerTransition()]
})
export class TailwindModalComponent implements OnInit {

  isModalRendered = false;
  shouldBeVisible = true;
  @Output() onClose = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
  }

  isOpened() {
    return this.isModalRendered;
  }

  open() {
    if (this.isModalRendered) {
      this.isModalRendered = false;
    }
    this.disableScroll();
    setTimeout(() => {
      this.isModalRendered = true;
      this.shouldBeVisible = true;
    });
  }

  clickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (targetElement.classList.contains('outside')) {
      this.close();
    }
  }

  close() {
    return new Promise<void>((resolve) => {
      this.shouldBeVisible = false;
      setTimeout(() => {
        this.isModalRendered = false;
        this.enableScroll();
        resolve();
      }, 400);
      this.onClose.emit();
    });
  }

  private disableScroll() {
    document.body.style.overflow = 'hidden';
  }

  private enableScroll() {
    document.body.style.overflow = 'auto';
  }
}


