import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {routerTransition} from '@app/shared-module/utils/router.animations';
import {tablesLimit} from '@app/shared-module/utils/utils';
import {Subject, Subscription} from 'rxjs';

export enum SortingState {
  SORT_NONE = 0,
  SORT_ASCENDING = 1,
  SORT_DESCENDING = 2
}

export class SortData {
  columnName: string;
  sortingState: SortingState;
}

@Component({
  selector: "design-tailwind-table",
  templateUrl: "./tailwind-table.component.html",
  styleUrls: ["./tailwind-table.component.scss"],
  animations: [routerTransition()]
})
export class TailwindTableComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor() {
  }

  @Input() tableClass: string;
  @Input() tableThreadClass: string;
  @Input() tableBodyClass: string;
  @Input() tableBodyId: string;
  @Input() tableHeaderClass: string;
  @Input() tableHeaderClassLast: string;

  @Input() refreshTablePager: Subject<number>;
  private refreshTablePagerSubscription: Subscription;
  @ViewChild('tableHeaders') tableHeaders!: ElementRef;
  @ViewChild('tableBody') tableBody!: ElementRef;

  // Sorting
  @Input() sortable = true;
  private sortingState: number = SortingState.SORT_NONE;
  private columnNames: string[] = [];
  private currentSortByColumnName: string;
  @ViewChild('sortElementTemplate') sortElementTemplate!: ViewContainerRef;
  @Output() onSort = new EventEmitter();

  // Pager
  @Input() showPager = true;
  @Input() pagerMaxPagesBeforeNavigation = 10;
  @Input() itemsCount;
  @Input() itemsPerPage;
  @Input() itemsLimit = tablesLimit;
  protected showPagerNavigation: boolean;
  protected pagerRenderRange: number[] = [];
  protected pages = 0;
  protected currentPage: number;
  @Output() onPageSelect = new EventEmitter();

  ngOnInit() {
    this.pagerInit(this.itemsCount);
    this.refreshTablePagerSubscription = this.refreshTablePager.subscribe((itemsCount) => {
      this.pagerInit(itemsCount);
    });
  }

  ngOnDestroy() {
    if (this.refreshTablePagerSubscription) { this.refreshTablePagerSubscription.unsubscribe(); }
  }

  pagerInit(itemsCount) {
    this.itemsCount = itemsCount;
    this.pages = this.pages = Math.ceil(this.itemsCount / this.itemsPerPage);
    this.currentPage = 0;
    this.showPagerNavigation = (this.pages > this.pagerMaxPagesBeforeNavigation);
    this.pagerRenderRange = [0, this.showPagerNavigation ? this.pagerMaxPagesBeforeNavigation : this.pages];
    this.goToPage(this.currentPage);
  }

  ngAfterViewInit() {
    // add class to table Header
    const tableHeaders = Array.from(this.tableHeaders.nativeElement.children) as HTMLElement[];
    tableHeaders.forEach((header, index) => {
      const headerIndex = index + 1;
      if (headerIndex < tableHeaders.length) {
        this.addClassToElement(header, this.tableHeaderClass);
      } else {
        this.addClassToElement(header, this.tableHeaderClassLast);
      }
      if (this.sortable && header.classList.contains('sortable')) {
        const sortElementTemplate = this.sortElementTemplate.createEmbeddedView(null);
        // append the sorting template to each table header
        sortElementTemplate.rootNodes.forEach(node => header.append(node));
        this.columnNames.push(header.id);
        this.processSort('');
      }
    });

    // set the id to each <tr> tag in the tableBody sector.
    const tableBodyChildren = Array.from(this.tableBody.nativeElement.children) as HTMLElement[];
    tableBodyChildren.filter(it => it && it.tagName === 'TR').forEach((row, index) => {
      row.id = `${this.tableBodyId}-${index + 1}`;
    });
  }

  addClassToElement(element: HTMLElement, className: string, divider: string = ' ') {
    const classes = className.split(divider);
    classes.forEach(c => element.className += `${divider}${c}`);
  }

  sort(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const parentElement = targetElement.closest('th');
    this.processSort(parentElement.id);
    this.resetPager();
  }

  processSort(selectedColumn: string) {
    const tableHeaders = this.tableHeaders.nativeElement as HTMLElement;
    const sortIcons = Array.from(tableHeaders.getElementsByClassName('sort-icon')) as HTMLElement[];

    if (this.currentSortByColumnName !== selectedColumn) {
      this.sortingState = SortingState.SORT_ASCENDING;
      this.currentSortByColumnName = selectedColumn;
    } else {
      this.sortingState++;
      if (this.sortingState < SortingState.SORT_NONE || this.sortingState > SortingState.SORT_DESCENDING) {
        this.sortingState = SortingState.SORT_NONE;
      }
    }

    sortIcons.forEach(sortIcon => {
      if (sortIcon.parentElement.id !== this.currentSortByColumnName) {
        sortIcon.innerHTML = this.getSortingIconForColumn(SortingState.SORT_NONE);
      } else {
        sortIcon.innerHTML = this.getSortingIconForColumn(this.sortingState);
      }
    });
    if (this.currentSortByColumnName.length) {
      this.onSort.emit({columnName: this.currentSortByColumnName, sortingState: this.sortingState} as SortData);
    }
  }

  private getSortingIconForColumn(sortingState: SortingState): string {
    const iconsPath = {
      [SortingState.SORT_ASCENDING]: `<span class="hero-arrow-up"></span>`,
      [SortingState.SORT_DESCENDING]: `<span class="hero-arrow-down"></span>`,
      default: `<span class="hero-arrows-up-down"></span>`
    };
    return iconsPath[sortingState] || iconsPath.default;
  }

  // Pager
  resetPager() {
    this.goToPage(0);
    this.pagerRenderRange = [0, this.showPagerNavigation ? this.pagerMaxPagesBeforeNavigation : this.pages];
  }
  goToPage(pageId: number) {
    this.currentPage = pageId;
    this.onPageSelect.emit(this.currentPage);
  }
  pagerGoForward() {
    this.pagerRenderRange[0]++;
    this.pagerRenderRange[1]++;
    this.workoutPagesForPager();
  }
  pagerGoBackward() {
    this.pagerRenderRange[0]--;
    this.pagerRenderRange[1]--;
    this.workoutPagesForPager();
  }
  workoutPagesForPager() {
    let from = this.pagerRenderRange[0];
    let to = this.pagerRenderRange[1];

    if (to > this.pages) {
      to = this.pages;
    }
    if (from < 0) {
      from = 0;
    }

    const pageDiff = (to - from);
    if (pageDiff !== this.pagerMaxPagesBeforeNavigation) {
      if (from <= 0) {
        from = 0;
        to++;
      } else if (to >= this.pages) {
        to = this.pages;
        from--;
      }
    }

    this.pagerRenderRange[0] = from;
    this.pagerRenderRange[1] = to;
  }
}


